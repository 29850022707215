<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h2 style="font-size:1.2em" class="my-4 text-secondary">Dashboard</h2>
      </div>
      <div class="col-xl-6 col-md-6 mb-4">
        <div class="card border-left-danger shadow py-4">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-md font-weight-bold text-danger text-uppercase mb-3"
                >
                  USERS
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  {{ users ? users.length : 0 }}
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-users fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-6 col-md-6 mb-4">
        <div class="card border-left-primary shadow h-200 py-4">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-md font-weight-bold text-primary text-uppercase mb-3"
                >
                  ENTITIES
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  {{ entities ? entities.length : 0 }}
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-sitemap fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-6 col-md-6 mb-4">
        <div class="card border-left-success shadow h-200 py-4">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-md font-weight-bold text-success text-uppercase mb-3"
                >
                  Market Places
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  {{ marketPlaces ? marketPlaces.length : 0 }}
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-search-dollar fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-6 col-md-6 mb-4">
        <div class="card border-left-info shadow h-200 py-4">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-md font-weight-bold text-info text-uppercase mb-3"
                >
                  Payments & Transactions
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  {{ allTokens ? allTokens.length : 0 }}
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-money-check-alt fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-6 col-md-6 mb-4" v-if="currentUser.role == 'Super'">
        <div class="card border-left-warning shadow h-200 py-4">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-md font-weight-bold text-warning text-uppercase mb-3"
                >
                  GLOBAL ADMINS
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  {{ globalAdmins ? globalAdmins.length : 0 }}
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-globe-europe fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-6 col-md-6 mb-4" v-if="currentUser.role == 'Global'">
        <div class="card border-left-warning shadow h-200 py-4">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-md font-weight-bold text-warning text-uppercase mb-3"
                >
                  Market ADMINS
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  {{ marketAdmins ? marketAdmins.length : 0 }}
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-comment-dollar fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-6 col-md-6 mb-4" v-if="currentUser.role == 'Market'">
        <div class="card border-left-warning shadow h-200 py-4">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-md font-weight-bold text-warning text-uppercase mb-3"
                >
                  Markets
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  {{ markets ? markets.length : 0 }}
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-poll fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import storage from "../helpers/storage";

export default {
  data() {
    return {};
  },
  computed: {
    currentUser() {
      return storage.get("currentUser");
    },
    ...mapGetters([
      "allTokens",
      "users",
      "entities",
      "marketPlaces",
      "globalAdmins",
      "marketAdmins",
      "markets",
    ]),
  },
  created() {
    this.$store.dispatch("getEntities");
    this.$store.dispatch("getUsers");
    this.$store.dispatch("fetchAllMarketPlaces");
    this.$store.dispatch("getGlobalAdmins");
    this.$store.dispatch("getAllMarketAdmins");
    this.$store.dispatch("getAllMarkets");
    this.$store.dispatch("fetchAllTokens");
  },
};
</script>

<style></style>
