<template>
    <div class="row justify-content-center">
        <div class="col-lg-11 col-md-8 col-sm-11">
            <div :class="type" class="text-center alert" role="alert">
                <strong>{{message}}</strong>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:['message','type']    
}
</script>